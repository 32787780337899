<template>
  <el-row class="movie-list">
    <el-col :md="16" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传文件</span>
          </div>
          <div class="text item">
            <uploader
              v-if="options !== null"
              class="uploader-example"
              :options="options"
              :auto-start="true"
              @file-added="onFileAdded"
              @file-success="onFileSuccess"
              @file-progress="onFileProgress"
              @file-error="onFileError"
            >
              <uploader-unsupport />
              <uploader-drop>
                <p>拖动文件到此处或</p>
                <uploader-btn :attrs="attrs">选择文件</uploader-btn>
              </uploader-drop>
              <uploader-list />
            </uploader>
          </div>
        </el-card>
      </el-row>
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>文件信息</span>
          </div>
          <div class="text item">
            <el-form ref="form" :model="uploadedFile" label-width="80px">
              <el-form-item label="UploadId">
                <el-input v-model="uploadedFile.uploadId" style="width: 70%; padding-right: 10px" readonly />
              </el-form-item>
              <el-form-item label="URL">
                <el-input v-model="uploadedFile.url" type="textarea" style="width: 70%; padding-right: 10px" readonly />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { getFileChannelInfo } from '@/api/file'
import { hashFile } from '@/utils/functions'

export default {
  name: 'PublishFile',
  data() {
    return {
      // ****************************************************************************************************************
      options: null,
      attrs: {
        accept: '*'
      },
      uploadedFile: {
        uploadId: 'null',
        url: ''
      }
    }
  },
  created() {
    getFileChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.options = {
          target: resData.ossUrl,
          // 分块大小 10MB
          chunkSize: 1024 * 1024 * 10,
          // 失败自动重传次数
          maxChunkRetries: 3,
          fileParameterName: 'file',
          testChunks: true,
          // 服务器分片校验函数, 秒传及断点续传基础
          checkChunkUploadedByResponse: function(chunk, message) {
            const objMessage = JSON.parse(message)
            const respData = objMessage.data
            if (respData.skipUpload) {
              return true
            }
            return (respData.uploaded || []).indexOf(chunk.offset + 1) >= 0
          },
          query: (file, chunk) => {
            return {
              channelCode: resData.channelCode,
              multiparts: ''
            }
          },
          headers: {
            Authorization: 'Bearer ' + resData.token
          },
          withCredentials: false
        }
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  mounted() {
  },
  methods: {
    // ****************************************************************************************************************
    onFileAdded(file) {
      if (file.file.size > 1024 * 1024 * 1024 * 20) {
        file.cancel()
        this.$notify({
          title: '提示',
          message: '文件应小于 20GB',
          type: 'warning',
          duration: 3000
        })
        return
      }

      file.pause()
      hashFile(file.file).then(result => {
        this.startUpload(result.sha256sum, file)
      })
    },
    startUpload(sha256sum, file) {
      file.uniqueIdentifier = sha256sum
      file.resume()
      // this.statusRemove(file.id)
    },
    onFileProgress(rootFile, file, chunk) {
    },
    onFileSuccess(rootFile, file, response, chunk) {
      const resp = JSON.parse(response)
      if (resp.code === 0) {
        this.uploadedFile.uploadId = resp.data.uploadId
        this.uploadedFile.url = resp.data.url
        this.$notify({
          title: '提示',
          message: '文件已上传',
          type: 'warning',
          duration: 3000
        })
      } else {
        this.$notify({
          title: '提示',
          message: resp.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    onFileError(rootFile, file, response, chunk) {
      // const res = JSON.parse(response)
      this.$notify({
        title: '提示',
        message: '文件上传错误',
        type: 'warning',
        duration: 3000
      })
    }
  }
}
</script>

<style>
.uploader-example {
  width: 500px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
